/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.5.0";
import "assets/css/demo.css";

import ExtendedTables from "views/Order/ExtendedTables";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51K9af5BfGfOYVrvwzumqhvEMn4mkG8P45l3B2u2ertsjdJxGfPOwfX7RxTjN1RaYLP6COVMAgC3xFxdPs4ulsWd800qUHy0sL1');



ReactDOM.render(
  <Elements stripe={stripePromise}>
    <div className="wrapper">
      <div className="main-panel" style={{width: "100%", overflowY: 'scroll'}}>
        <ExtendedTables />
      </div>
    </div>
  </Elements>
  ,
  document.getElementById("root")
);
